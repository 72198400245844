import React, { useState } from "react";
import axios from "axios";
import ProfileHeader from "../common/ProfileHeader";

const CustomMessageForm = ({ phoneNumber }) => {
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add a loading state for UX
  const [charCount, setCharCount] = useState(0); // State for character counter
  const maxCharLimit = 500; // Maximum allowed characters

  // Helper function to validate the phone number format
  const isValidPhoneNumber = (phoneNumber) => /^\d{10,15}$/.test(phoneNumber);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!message.trim()) {
      setErrorMessage("Message cannot be empty.");
      return;
    }

    // Validate phone number before proceeding
    if (!isValidPhoneNumber(phoneNumber)) {
      setErrorMessage("Invalid phone number format.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}send-whatsapp`,
        {
          phoneNumber,
          message: message.trim(),
        }
      );

      // If the backend returns a WhatsApp URL, open it in a new tab
      if (response.data && response.data.url) {
        window.open(response.data.url, "_blank");
        setMessage(""); // Clear the message after successful submission
        setCharCount(0); // Reset the character counter
      } else {
        setErrorMessage("Failed to send the message. Try again later.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setErrorMessage(
        error.response?.data?.error || "Error occurred. Try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setMessage(""); // Clear the message input
    setCharCount(0); // Reset character counter
    setErrorMessage(""); // Clear any error messages
  };

  const handleMessageChange = (e) => {
    const newMessage = e.target.value;
    if (newMessage.length <= maxCharLimit) {
      setMessage(newMessage);
      setCharCount(newMessage.length);
    }
  };

  return (
    <>
      <ProfileHeader />
      <div className="max-w-md mx-auto mt-4 bg-slate-600 backdrop-blur-lg rounded-lg shadow-lg border border-white/30">
        {/* Profile Header and Title */}
        <div className="text-center mt-8">
          <h2 className="mt-2 text-2xl font-semibold text-gray-900 duration-500 ease-in-out transform hover:scale-105">
            Custom message me !!!
          </h2>
        </div>

        {/* Custom Message Form */}
        <form onSubmit={handleSubmit} className="mt-6 p-6">
          <p className="text-white font-lilita mb-2 text-2xl tracking-wider">
            Enter your message here!
          </p>

          <textarea
            placeholder="Your message..."
            value={message}
            onChange={handleMessageChange}
            className="w-full p-2 rounded-md text-gray-300 bg-black placeholder-gray-500 focus:outline-none"
            rows="5"
            maxLength={maxCharLimit} // Limit message length to maxCharLimit
          ></textarea>

          {/* Character Counter */}
          <p className="text-gray-200 text-sm text-right">
            {charCount}/{maxCharLimit} characters
          </p>

          {/* Buttons */}
          <div className="mt-4 flex justify-between">
            <button
              type="submit"
              disabled={!message.trim() || isLoading} // Disable if empty or loading
              className={`px-4 py-2 font-semibold rounded-md transition-all ${
                message.trim()
                  ? "bg-blue-600 text-white hover:bg-white hover:text-blue-500"
                  : "bg-gray-400 text-gray-700 cursor-not-allowed"
              } ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              {isLoading ? "Sending..." : "SUBMIT"}
            </button>

            <button
              type="button"
              onClick={handleClear}
              className="px-4 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-white hover:text-red-500"
              disabled={isLoading}
            >
              CLEAR
            </button>
          </div>

          {/* Error Message */}
          {errorMessage && (
            <div className="text-red-500 mt-4 font-semibold">
              {errorMessage}
            </div>
          )}
        </form>
      </div>

      {/* Spacer to prevent footer overlap */}
      <div className="mb-16"></div>
    </>
  );
};

export default CustomMessageForm;
