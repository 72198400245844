import React from "react";

const SearchManagement = ({
  searchQuery,
  setSearchQuery,
  handleSearch,
  handleCancel,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="">
      <h3 className="text-2xl ml-1 font-bold text-white font-asap-condensed tracking-wider pt-2">
        Search Management people below 👇
      </h3>
      <div className="bg-white/20 bg-opacity-50 mt-1 tracking-wider text-xl text-white font-bold py-2 px-4 rounded-md backdrop-blur-lg shadow-lg border border-white/10">
        <input
          type="text"
          placeholder="Search Name here..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          className="w-full p-2 mt-4 rounded-md border-2 border-gray-700 hover:border-gray-300 text-gray-800"
          spellCheck="false" // Disable spell check to remove the red underline
        />

        <div className="mt-4 flex justify-between">
          <button
            onClick={handleCancel}
            className="font-asap-condensed px-4 py-2 bg-red-500 font-bold text-white rounded-lg duration-500 ease-in-out transform hover:scale-110 hover:bg-gray-100 hover:text-red-600"
          >
            CLEAR
          </button>
          <button
            onClick={handleSearch}
            className="font-asap-condensed bg-black tracking-wider text-lg text-white duration-500 ease-in-out transform hover:scale-110 font-bold py-2 px-4 rounded-lg hover:bg-white/60 backdrop-blur-lg shadow-lg border border-white/10 hover:text-gray-700"
          >
            SEARCH
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchManagement;
