/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { QRCodeCanvas } from "qrcode.react"; // Import a QR code generator package
import ProfileHeader from "../common/ProfileHeader";

const QRCodePage = () => {
  // Use production URL in the QR code value
  const productionURL = "https://manimozhi.vcard-aam.com/";

  return (
    <>
      <ProfileHeader />
      <div className="max-w-md mx-auto text-center text-white bg-gray-800 rounded-lg shadow-lg">
        <div className="flex flex-col items-center justify-center h-fit mt-3 mb-10 rounded-xl bg-gray-800">
          <h1 className="text-4xl font-dancing-script tracking-wide mb-5 mt-9">
            Visit my VCard
          </h1>
          <QRCodeCanvas value={productionURL} size={256} level={"H"} />
          <p className="text-yellow-500 text-lg mt-5 mb-12 animate-blink">
            Scan me to visit VCard
          </p>
        </div>
      </div>
    </>
  );
};

export default QRCodePage;
