import React, { useState } from "react";
import ContactInfo from "../common/ContactInfo";

const ContactDetails = ({ phoneNumber }) => {
  // Accept phoneNumber as a prop
  const [showFullEmail, setShowFullEmail] = useState(false); // State to toggle email visibility

  return (
    <div>
      <div className="grid grid-cols-2 gap-6 mt-6 space-x-14">
        {/* Email Section */}
        <ContactInfo
          type="email"
          label="E-mail Address"
          value={
            <>
              kutti_mani2003@yahoo
              <span className="whitespace-nowrap break-all">.com</span>
            </>
          }
          showFullEmail={showFullEmail}
          toggleEmail={() => setShowFullEmail(!showFullEmail)}
          className="break-words max-w-full px-4 text-sm sm:text-base"
        />

        {/* Phone Section */}
        <ContactInfo
          type="phone"
          label="Phone Number"
          value={`(+27) ${phoneNumber}`}
          href={`tel:+27${phoneNumber}`}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mt-6 space-x-14">
        {/* Birthday Section */}
        <ContactInfo
          type="website"
          label="V4U Africa Pvt. Ltd."
          value="V4U Africa"
          href="https://v4uafrica.com/"
          target="_blank"
        />

        {/* Location Section */}
        <ContactInfo
          type="restaurant"
          label="Our Restaurant"
          value="Flavours of India"
          href="https://www.ubereats.com/za/store/flavours-of-india-rsa/50yYiDBJQl2Z9wPDQEEhug"
          target="_blank"
        />
      </div>
    </div>
  );
};

export default ContactDetails;
