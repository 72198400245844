/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import debounce from "lodash.debounce";

const MakeAppointment = () => {
  // State for form fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errors, setErrors] = useState({});
  const [isInputInvalid, setIsInputInvalid] = useState(false);

  const spanRef = useRef(null);
  const sectionRef = useRef(null);
  const [visibilityRatio, setVisibilityRatio] = useState(0);

  const phoneNumber = "+27620556906";

  // Debounced function for visibility ratio change (for underline effect)
  const handleVisibilityChange = useCallback(
    debounce((ratio) => {
      setVisibilityRatio(ratio);
    }, 100),
    []
  );

  // Intersection Observer to detect visibility ratio
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) =>
          handleVisibilityChange(entry.intersectionRatio)
        );
      },
      { threshold: Array.from(Array(101).keys(), (v) => v / 100) }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [handleVisibilityChange]);

  // Update underline span width based on visibility ratio
  useEffect(() => {
    if (spanRef.current) {
      const fullWidth = spanRef.current.parentElement.offsetWidth;
      const adjustedWidth = fullWidth * visibilityRatio;
      spanRef.current.style.width = `${adjustedWidth}px`;
    }
  }, [visibilityRatio]);

  // Form validation
  const validateForm = () => {
    const formErrors = {};
    if (!firstName.trim()) {
      formErrors.firstName = "Please provide your first name.";
    }
    return formErrors;
  };

  // Function to handle extra spaces in name fields
  const cleanInput = (input) => {
    return input.replace(/\s+/g, " ").trim(); // Collapse multiple spaces into one and trim leading/trailing spaces
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const cleanedFirstName = cleanInput(firstName);
    const cleanedLastName = cleanInput(lastName);

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsInputInvalid(true);
      setTimeout(() => setIsInputInvalid(false), 1000);
    } else {
      setErrors({});
      setIsInputInvalid(false);

      // Format the WhatsApp message
      const formattedMessage = `Hi, I am ${cleanedFirstName}${
        cleanedLastName ? ` ${cleanedLastName}` : ""
      }, I would like to book an appointment.`;

      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
        formattedMessage
      )}`;
      window.open(whatsappUrl, "_blank");

      // Clear form
      setFirstName("");
      setLastName("");
    }
  };

  // Clear the error when user starts typing in the first name field
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    if (errors.firstName) {
      setErrors((prevErrors) => {
        const { firstName, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  };

  return (
    <div
      className="max-w-md mx-auto mt-8 p-6 bg-white/20 text-white rounded-lg shadow-lg"
      ref={sectionRef}
    >
      {/* Heading */}
      <h1 className="text-3xl text-center font-dancing-script mb-4">
        Book Appointment
        <span
          ref={spanRef}
          className="block mx-auto h-1 bg-yellow-500 mb-4 transition-all duration-700 ease-in-out"
        ></span>
      </h1>

      {/* Form */}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="firstName" className="block mb-1">
            First Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="firstName"
            className={`w-full p-2 bg-gray-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500 transition-all duration-300 ${
              isInputInvalid ? "border-2 border-red-500" : ""
            }`}
            value={firstName}
            onChange={handleFirstNameChange}
          />
          {errors.firstName && (
            <span className="text-red-500 text-sm">{errors.firstName}</span>
          )}
        </div>

        <div>
          <label htmlFor="lastName" className="block mb-1">
            Last Name (Optional)
          </label>
          <input
            type="text"
            id="lastName"
            className="w-full p-2 bg-gray-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500 transition-all duration-300"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        <div>
          <button
            type="submit"
            className="w-full py-2 bg-green-600 text-white font-semibold rounded-md transition-all duration-500 ease-in-out transform hover:scale-105 hover:bg-white hover:text-green-500"
          >
            Book an Appointment
          </button>
        </div>
      </form>
    </div>
  );
};

export default MakeAppointment;
