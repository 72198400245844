import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import VCard from "../home/VCard";
import WhatWeDo from "../common/whatWeDo/WhatWeDo";
import Restaurant from "./v4u_restaurant/Restaurant";

const SwipeablePages = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);

  // Function to check if there is a text selection
  const isTextSelected = () => {
    const selection = window.getSelection();
    return selection && selection.toString().length > 0; // Return true if text is selected
  };

  // Pages that can be swiped between
  const pages = [
    { component: <VCard />, path: "/" },
    { component: <WhatWeDo />, path: "/" },
    { component: <Restaurant />, path: "/" },
  ];

  // Swipe handlers for both touch and mouse dragging
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (!isTextSelected()) {
        // Only swipe if no text is selected
        const nextPage = Math.min(pages.length - 1, currentPage + 1);
        setCurrentPage(nextPage);
        navigate(pages[nextPage].path); // Use navigate to change the URL
        window.scrollTo(0, 0); // Scroll to top when navigating to the next page
      }
    },
    onSwipedRight: () => {
      if (!isTextSelected()) {
        // Only swipe if no text is selected
        const prevPage = Math.max(0, currentPage - 1);
        setCurrentPage(prevPage);
        navigate(pages[prevPage].path); // Use navigate to change the URL
        window.scrollTo(0, 0); // Scroll to top when navigating to the previous page
      }
    },
    delta: 70, // Minimum swipe distance (in pixels)
    trackMouse: true, // Enable swipe support for mouse dragging
  });

  // Handle dot click to navigate to the corresponding page
  const handleDotClick = (index) => {
    setCurrentPage(index); // Set current page
    navigate(pages[index].path); // Navigate to corresponding page path
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div {...handlers} className="swipeable-container relative">
      {/* Page Content */}
      {pages[currentPage].component}

      {/* Slider Dots (Fixed at the Top) */}
      <div
        className="fixed top-4 left-1/2 transform -translate-x-1/2 flex justify-center space-x-2 z-50"
        style={{ position: "fixed" }} // Fixed position to keep dots at the top
      >
        {pages.map((_, index) => (
          <span
            key={index}
            onClick={() => handleDotClick(index)} // Handle dot click
            className={`w-4 h-4 mt-3 rounded-full cursor-pointer transition-all duration-300 ${
              index === currentPage ? "bg-yellow-500" : "bg-white"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default SwipeablePages;
