import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CommunityDirectory from "./components/directory/CommunityDirectory";
import ManagementPerson from "./components/shared/ManagementPerson";
import CustomMessageForm from "./components/shared/CustomMessageForm";
import FooterNav from "./components/common/FooterNav";
import SearchPerson from "./components/search/SearchPerson";
import SearchCompany from "./components/search/SearchCompany";
import QRCodePage from "./components/qr/QRCodePage";
import Dashboard from "./components/common/Dashboard";
import { UserProvider } from "./context/UserContext";
import SwipeablePages from "./components/common/SwipablePages";

const App = () => {
  // Function to record visits
  const recordVisit = async (visitId, userId) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}record-visit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ visitId, userId }), // Pass userId along with visitId
      });
    } catch (error) {
      console.error("Error recording visit:", error);
    }
  };

  // Function to generate a unique ID for each visitor
  const generateUniqueId = () => {
    return "visitor-" + Math.random().toString(36).substr(2, 16);
  };

  // Function to set a cookie
  const setCookie = (name, value, days) => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie =
      name +
      "=" +
      encodeURIComponent(value) +
      "; expires=" +
      expires +
      "; path=/";
  };

  // Function to get a cookie
  const getCookie = (name) => {
    return document.cookie.split("; ").reduce((r, v) => {
      const parts = v.split("=");
      return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, "");
  };

  // Use useEffect to track visits globally
  useEffect(() => {
    const visitId = localStorage.getItem("visitId") || getCookie("visitId");

    // Simulate logged-in user (replace this with actual userId from context)
    const userId = 1; // Placeholder userId, replace it with dynamic user ID

    if (!visitId) {
      const newVisitId = generateUniqueId();
      localStorage.setItem("visitId", newVisitId);
      setCookie("visitId", newVisitId, 7); // Set cookie for 7 days
      console.log("Recording new visit:", newVisitId);
      recordVisit(newVisitId, userId); // Only call the API if visitId is new
    }
  }, []);

  return (
    <UserProvider>
      <Router>
        <Routes>
          {/* Swipeable pages */}
          <Route path="/" element={<SwipeablePages />} />

          {/* Non-swipeable pages */}
          <Route path="/community-directory" element={<CommunityDirectory />} />
          <Route path="/share-report" element={<ManagementPerson />} />
          <Route
            path="/custom-message"
            // element={<CustomMessageForm phoneNumber={+27620556906} />}
            element={<CustomMessageForm phoneNumber={+919266850962} />}
          />
          <Route path="/search-person" element={<SearchPerson />} />
          <Route path="/search-company" element={<SearchCompany />} />
          <Route path="/qr-code" element={<QRCodePage />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
        <FooterNav />
      </Router>
    </UserProvider>
  );
};

export default App;
