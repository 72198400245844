const galleryImages = [
  {
    name: "South Special",
    imageUrl:
      "https://miro.medium.com/v2/resize:fit:1358/1*8W3i6485tH9aoG82xeFpSQ.jpeg",
  },
  {
    name: "Punjabi Samosa",
    imageUrl:
      "https://www.tripsavvy.com/thmb/f-VZrcG8tCbTyAw3pcGI7RGBpx8=/2121x1414/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-639704020-7fc1d09875084524bada655b9ff7e678.jpg",
  },
  {
    name: "Egg Dish",
    imageUrl: "https://wallpaperaccess.com/full/1317096.jpg",
  },
  {
    name: "Indian Map Food",
    imageUrl:
      "https://letstripdesi.com/wp-content/uploads/2022/03/5cbc5ded512c0c3e8b6c782d_1555848685957-1229x1536.jpg",
  },
  {
    name: "Kohinoor",
    imageUrl:
      "https://kohinoor-joy.com/wp-content/uploads/2016/11/north-Indian-food-featured-image.jpg",
  },
  {
    name: "South Indian Thaali",
    imageUrl:
      "https://img.freepik.com/premium-photo/traditional-south-indian-meal-food-served-big-banana-leaf-food-platter-complete-thali_747653-1876.jpg",
  },
];

export default galleryImages;
