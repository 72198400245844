import React, { useState, useRef, useCallback } from "react";
import axios from "axios";
import ProfileHeader from "../common/ProfileHeader";
import { debounce } from "lodash";

const SearchPerson = () => {
  const [personName, setPersonName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [totalItems, setTotalItems] = useState(0); // New state to track totalItems
  const [errorMessage, setErrorMessage] = useState("");
  const resultsRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(async () => {
      if (!personName.trim()) {
        setErrorMessage("Please fill in this field.");
        setSearchResults([]); // Clear previous search results
        setTotalItems(0); // Reset totalItems to 0
        return;
      }
      setErrorMessage(""); // Clear previous errors

      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_BASE_URL
          }search-persons?name=${encodeURIComponent(personName)}`
        );

        // Extract totalItems from the first object in the array
        const totalItems = response.data.data[0].totalItems;

        // Set the search results (excluding the first object)
        const results = response.data.data.slice(1);

        setTotalItems(totalItems);
        setSearchResults(results);

        // Scroll to the results section
        setTimeout(() => {
          if (resultsRef.current) {
            resultsRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 100); // Slight delay to ensure DOM update
      } catch (error) {
        if (error.response) {
          const { status, data } = error.response;

          if (status === 400) {
            // Handle 400 errors: Input validation, length restrictions
            setErrorMessage(
              data.error || "Invalid request. Please check your input."
            );
          } else if (status === 429) {
            // Handle 429 errors: Rate limiting
            setErrorMessage("Too many requests. Please try again later.");
          } else if (status === 404) {
            setErrorMessage("No results found.");
          } else {
            setErrorMessage("An unexpected error occurred. Please try again.");
          }
        } else {
          setErrorMessage(
            "Failed to fetch results. Please check your network connection."
          );
        }
        setSearchResults([]); // Clear previous search results on error
        setTotalItems(0); // Reset totalItems to 0 on error
      }
    }, 300), // Debounce the function, setting a delay of 300ms
    [personName]
  );

  const handleCancel = () => {
    setPersonName(""); // Clear the input field
    setSearchResults([]); // Clear the search results
    setErrorMessage(""); // Clear any error messages
    setTotalItems(0); // Reset totalItems
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <ProfileHeader />
      <div className="max-w-md mx-auto mt-4 mb-16 bg-gray-800 backdrop-blur-lg rounded-lg shadow-lg p-6 border border-white/10">
        <div className="bg-white/20 bg-opacity-50 mt-1 tracking-wider text-xl text-white font-bold py-2 px-4 rounded-md backdrop-blur-lg shadow-lg border border-white/10">
          <h3 className="text-2xl ml-1 font-bold text-white font-asap-condensed tracking-wider pt-2">
            Search a person
          </h3>

          <div>
            <input
              type="text"
              placeholder="Enter a name ..."
              value={personName}
              onChange={(e) => setPersonName(e.target.value)}
              onKeyDown={handleKeyDown} // Trigger search on Enter key, with debounce
              className="w-full p-2 mt-4 rounded-md border-2 border-gray-700 hover:border-gray-300 text-gray-800"
              spellCheck="false"
            />
          </div>

          <div className="mt-4 flex justify-between space-x-4">
            <button
              onClick={handleCancel}
              className="font-asap-condensed px-4 py-2 bg-red-500 font-bold text-white rounded-md duration-500 ease-in-out transform hover:scale-110 hover:bg-gray-100 hover:text-red-600"
            >
              CLEAR
            </button>
            <button
              onClick={handleSearch}
              className="font-asap-condensed bg-black tracking-wider text-lg text-white duration-500 ease-in-out transform hover:scale-110 font-bold py-2 px-4 rounded-md hover:bg-white/60 backdrop-blur-lg shadow-lg border border-white/10 hover:text-gray-700"
            >
              SEARCH
            </button>
          </div>
          {errorMessage && (
            <div className="text-red-500 text-sm text-outline text-center m-2">
              {errorMessage}
            </div>
          )}
        </div>

        {/* Display totalItems and search results */}
        <div>
          {totalItems > 0 && (
            <div className="text-yellow-500 text-lg font-bold mt-4 text-center">
              Total Results Found: {totalItems}
            </div>
          )}

          {searchResults.length > 0 && (
            <div
              ref={resultsRef}
              className="bg-white/20 bg-opacity-50 mt-6 tracking-wider text-xl text-white font-bold py-2 px-4 rounded-md backdrop-blur-lg shadow-lg border border-white/10"
            >
              {searchResults.map((result, index) => (
                <div
                  key={index}
                  className="flex flex-col sm:flex-row items-center mt-5 mb-5 bg-white/20 p-3 rounded-xl duration-500 ease-in-out transform hover:scale-105"
                >
                  {/* Image on the left */}
                  <div className="flex-shrink-0">
                    <a
                      href={result.vcard_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={result.profile_pic}
                        alt={result.first_name}
                        className="w-28 h-28 sm:w-36 sm:h-36 rounded-full duration-500 ease-in-out transform hover:scale-110 border-4 border-white-800"
                      />
                    </a>
                  </div>

                  {/* Details on the right */}
                  <div className="sm:ml-6 mt-4 sm:mt-0 text-center sm:text-left">
                    <div className="py-2">
                      <p className="text-2xl font-bebas-neue tracking-wider font-semibold text-white duration-500 ease-in-out transform hover:scale-110">
                        {`${result.honorific} ${result.first_name} ${
                          result.middle_name || ""
                        } ${result.last_name}`}
                      </p>
                      <p className="text-yellow-400 text-lg font-bold font-asap-condensed tracking-wider">
                        {result.designation}
                      </p>
                    </div>

                    <div className="mt-2">
                      <button
                        onClick={() => window.open(result.vcard_link, "_blank")}
                        className="bg-green-600 font-asap-condensed tracking-wider text-gray-100 py-2 px-3 rounded-md text-center duration-500 ease-in-out transform hover:scale-110 hover:bg-white hover:text-green-600"
                      >
                        VIEW VCARD
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchPerson;
