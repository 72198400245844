import React, { useEffect, useState, useContext } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import UserContext from "../../context/UserContext";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/Card";
import { format } from "date-fns";
import { FiRefreshCw } from "react-icons/fi";

// Skeleton Loader Component with transition effect
const SkeletonLoader = () => (
  <div className="animate-pulse h-64 bg-gray-700 rounded-md opacity-0 transition-opacity duration-700 ease-in-out"></div>
);

const Dashboard = () => {
  const { user } = useContext(UserContext); // Use UserContext to get the userId
  const [metrics, setMetrics] = useState(null);
  const [visitorData, setVisitorData] = useState([]); // State to store visitor data for chart
  const [shouldRefresh, setShouldRefresh] = useState(false); // New state to trigger metrics refresh
  const [loading, setLoading] = useState(true); // New loading state
  const [dateRange, setDateRange] = useState("7days"); // New state for date range filter

  // This useEffect will handle recording the visit only on component mount
  useEffect(() => {
    // Get the visitId from localStorage or cookies
    const visitId = localStorage.getItem("visitId") || getCookie("visitId");

    // If a visitId already exists, it means the user has opened the website before
    if (visitId) {
      // console.log("User has already visited, skipping the API call.");
      return; // Exit early, preventing the API call
    }

    // If no visitId exists, it means this is the user's first visit
    const newVisitId = generateUniqueId(); // Generate a unique ID for the visit
    localStorage.setItem("visitId", newVisitId); // Store it in localStorage
    setCookie("visitId", newVisitId, 30); // Store it in a cookie for 30 days

    console.log("Recording new visit:", newVisitId);
    recordVisit(newVisitId, user.userId); // Call the API for the new visit

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userId]);

  // This useEffect will handle fetching metrics and visitor data
  useEffect(() => {
    // Function to fetch metrics
    const fetchMetrics = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}dashboard-metrics?user_id=${user.userId}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setMetrics(data.metrics);
      } catch (error) {
        console.error("Error fetching metrics:", error);
      }
    };

    // Function to fetch visitor data for the chart based on selected date range
    const fetchVisitorData = async () => {
      setLoading(true); // Set loading to true before starting fetch
      try {
        const apiURL = `${process.env.REACT_APP_API_BASE_URL}dashboard-visit-data?user_id=${user.userId}&range=${dateRange}`;
        const response = await fetch(apiURL);

        // Parse the response
        const result = await response.json();

        // Check if the status code is 200 (success)
        if (result.statusCode === 200) {
          // Extract the data object from the response
          const data = result.data.items;

          // Format the data for the chart
          const formattedData = data.map((item) => ({
            date: item.date, // Ensure the field names are correct
            visitors: item.count,
          }));

          setVisitorData(formattedData); // Set the formatted data for the chart
        } else {
          // Log an error message if the request failed
          console.error("Failed to fetch visitor data:", result.message);
        }
      } catch (error) {
        console.error("Error fetching visitor data:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    // Fetch metrics on load and also when `shouldRefresh` is triggered
    fetchMetrics();
    fetchVisitorData();

    // Reset the refresh flag after fetching updated metrics
    if (shouldRefresh) {
      setShouldRefresh(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userId, shouldRefresh, dateRange]);

  // Function to handle real-time updates and reset the filter to default (7days)
  const handleRefreshMetrics = () => {
    setShouldRefresh(true); // Trigger refresh in the useEffect
    setDateRange("7days"); // Reset the date range filter to default (7 days)
  };

  const generateUniqueId = () => {
    return "visitor-" + Math.random().toString(36).substr(2, 16);
  };

  const setCookie = (name, value, days) => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie =
      name +
      "=" +
      encodeURIComponent(value) +
      "; expires=" +
      expires +
      "; path=/";
  };

  const getCookie = (name) => {
    return document.cookie.split("; ").reduce((r, v) => {
      const parts = v.split("=");
      return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, "");
  };

  const recordVisit = async (visitId, userId) => {
    // Additional check to ensure we only call the API if the visitId doesn't exist
    if (localStorage.getItem("visitId") || getCookie("visitId")) {
      // console.log("API call prevented: Visit ID already exists.");
      return; // Prevent API call if visitId already exists
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}record-visit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ visitId, userId }),
        }
      );

      if (!response.ok) {
        console.error("Failed to record visit:", await response.json());
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log("API call successful");
    } catch (error) {
      console.error("Error recording visit:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const suffix =
      day === 1 || day === 21 || day === 31
        ? "st"
        : day === 2 || day === 22
        ? "nd"
        : day === 3 || day === 23
        ? "rd"
        : "th";
    return `${day}${suffix} ${format(date, "MMM")}, ${format(date, "yyyy")}`;
  };

  // Handle date range change
  const handleRangeChange = (e) => {
    setDateRange(e.target.value); // Update the date range for data fetching
  };

  return (
    <div className="dashboard-container mx-auto bg-gray-800 rounded-lg shadow-lg overflow-hidden p-4 sm:p-8 lg:p-12 max-w-md">
      <div className="">
        {/* Dashboard Title */}
        <hr className="border-t-2 border-yellow-500 mb-4" />
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center text-yellow-500 font-acme hover:text-white duration-500 ease-in-out transform hover:scale-110">
          Analytics <br /> Overview
        </h1>
        <hr className="border-t-2 border-yellow-500 my-4" />

        {/* Date Range Filter */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-4 mt-10">
          <div className="flex items-center space-x-4 mb-4 md:mb-0">
            <label className="text-white font-bold">Filter By: </label>
            <div className="relative inline-block">
              <select
                value={dateRange}
                onChange={handleRangeChange}
                className="bg-gray-700 text-white rounded px-3 py-2 pr-8 appearance-none"
              >
                <option value="7days">Last 7 Days</option>
                <option value="14days">Last 14 Days</option>
                <option value="1month">Last 1 Month</option>
              </select>

              {/* Custom dropdown arrow */}
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <svg
                  className="w-4 h-4 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </div>
            </div>
          </div>

          {/* Refresh Button on the Right */}
          <div className="flex justify-end">
            <button
              onClick={handleRefreshMetrics} // Trigger refresh when clicked
              className="text-blue-600 px-4 py-2 sm:px-6 sm:py-2 bg-white/5 hover:bg-white/10 rounded font-bold hover:text-white duration-500 ease-in-out transform hover:scale-110"
            >
              <FiRefreshCw size={30} /> {/* Refresh icon */}
            </button>
          </div>
        </div>
        {/* End of Refresh Button */}

        {/* Chart Section */}
        <Card className="bg-white/30 mx-auto max-w-full lg:max-w-3xl mt-7">
          <CardHeader>
            <CardTitle className="text-yellow-500 text-center font-dancing-script tracking-wide text-3xl sm:text-4xl">
              Visitors
            </CardTitle>
            <CardDescription className="text-center">
              (Total visitors per day)
            </CardDescription>
          </CardHeader>
          <CardContent className="mt-7">
            <div
              className={`transition-opacity duration-700 ease-in-out ${
                loading ? "opacity-100" : "opacity-0"
              }`}
            >
              {loading && <SkeletonLoader />}
            </div>

            <div
              className={`transition-opacity duration-700 ease-in-out ${
                loading ? "opacity-0" : "opacity-100"
              }`}
            >
              {!loading && visitorData.length > 0 ? (
                <ResponsiveContainer width="100%" height={300}>
                  <AreaChart
                    data={visitorData} // Use fetched visitor data
                    margin={{
                      top: 10,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="7 7" horizontal={false} />
                    <XAxis
                      dataKey="date"
                      tickLine={false}
                      axisLine={false}
                      tickMargin={8}
                      interval="preserveStartEnd"
                      tickFormatter={(value) => formatDate(value)} // Formats the date
                      height={70}
                      tick={{
                        fill: "#ffff", // Style the text
                        fontSize: 12,
                        wordBreak: "break-all",
                      }}
                    />
                    <Tooltip
                      content={({ payload, label }) => {
                        if (payload && payload.length) {
                          return (
                            <div className="custom-tooltip bg-white/10 p-2 rounded shadow text-md">
                              <p>{`Date: ${label}`}</p>
                              Visitors:{" "}
                              <span className="text-green-400 text-xl">
                                {payload[0].value}
                              </span>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Area
                      type="monotone"
                      dataKey="visitors"
                      stroke="#ffff"
                      fill="#3530AD"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              ) : (
                !loading && (
                  <p className="text-yellow-500 text-center mb-7 animate-blink">
                    {`No data for the past ${
                      dateRange === "7days"
                        ? "7"
                        : dateRange === "14days"
                        ? "14"
                        : "30"
                    } days.`}
                    <br />{" "}
                    {/* Line break to move the next part to a new line */}
                    {`Please select a different date range.`}
                  </p>
                )
              )}
            </div>
          </CardContent>
        </Card>

        <hr className="border-t-2 border-yellow-500 m-4 mt-14" />
        {/* Card Section */}
        <div className="card-section mt-4 space-y-5 p-4 lg:p-10 mb-16">
          <div className="card bg-white/30 hover:bg-white/20 hover:text-7xl rounded-lg p-3 text-center duration-500 ease-in-out transform hover:scale-105">
            <h3 className="text-2xl sm:text-3xl font-semibold font-oswald">
              Total Visitors
            </h3>
            <p className="text-3xl sm:text-4xl text-yellow-500 font-bold mt-3 duration-500 ease-in-out transform hover:scale-110">
              {metrics ? metrics.total_visitors : "Loading..."}
            </p>
          </div>

          <div className="card bg-white/30 hover:bg-white/20 hover:text-7xl rounded-lg p-4 text-center duration-500 ease-in-out transform hover:scale-105">
            <h3 className="text-2xl sm:text-3xl font-semibold font-oswald">
              Contacts Added
            </h3>
            <p className="text-3xl sm:text-4xl text-yellow-500 font-bold mt-3 duration-500 ease-in-out transform hover:scale-110">
              {metrics ? metrics.add_to_contact : "Loading..."}
            </p>
          </div>

          <div className="card bg-white/30 hover:bg-white/20 hover:text-7xl rounded-lg p-4 text-center duration-500 ease-in-out transform hover:scale-105">
            <h3 className="text-2xl sm:text-3xl font-semibold font-oswald">
              Total vCard Shares
            </h3>
            <p className="text-3xl sm:text-4xl text-yellow-500 font-bold mt-3 duration-500 ease-in-out transform hover:scale-110">
              {metrics ? metrics.vcard_share_count : "Loading..."}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
