import React, { useRef } from "react";
import ContactDetails from "../ContactDetails";
import V4Ugallery from "./V4Ugallery";
import V4Uservices from "./V4Uservices"; // Importing the V4Uservices component
import MakeAppointment from "./MakeAppointment";
import Products from "./Products";
import BusinessHours from "./businessHours/BusinessHours";
import RestaurantProfileHeader from "./RestaurantProfileHeader";

// Reusable HR component
const Divider = () => (
  <hr className="border-t-2 border-gray-400 rounded-full mt-10 m-2 mb-10" />
);

const Restaurant = () => {
  const spanRef = useRef(null); // Ref for the underline span

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div>
        {/* Header Section */}
        <RestaurantProfileHeader />

        {/* Main Container */}
        <div className="what-we-do-container max-w-md mx-auto mb-7 bg-gray-800 rounded-lg shadow-lg p-6 backdrop-blur-lg border border-white/10">
          {/* Flavours of India Title */}
          <div className="text-5xl text-center text-white hover:text-yellow-500 mb-7 tracking-wider font-semibold text-md font-acme duration-500 ease-in-out transform hover:scale-110">
            <h1>Flavours of India</h1>
          </div>

          {/* Divider */}
          <Divider />

          {/* Contact Information Section */}
          <div className="text-left p-4 pt-2 space-y-4">
            <h1 className="text-center text-white text-3xl font-dancing-script">
              Contacts
            </h1>
            <span className="block mx-auto w-12 h-1 bg-yellow-500 mb-4"></span>

            {/* ContactDetails Component */}
            <ContactDetails phoneNumber={688589691} />
          </div>

          {/* Divider */}
          <Divider />

          {/* Gallery Section */}
          <div className="bg-white/20 gallery-section max-w-md mx-auto text-center text-white py-10 rounded-2xl shadow-lg p-6">
            <h2 className="text-4xl font-dancing-script mb-8">Gallery</h2>
            <span
              ref={spanRef}
              className={`block mx-auto h-1 bg-yellow-500 mb-8 transition-all duration-700 ease-in-out`}
            ></span>
            <V4Ugallery />
          </div>

          {/* Our Services Section */}
          <V4Uservices />

          {/* Divider */}
          <Divider />

          {/* Products Section */}
          <Products />

          {/* Divider */}
          <Divider />

          {/* Booking Hours Section */}
          <BusinessHours />

          {/* Divider */}
          <Divider />

          {/* Book Appointment Section */}
          <MakeAppointment />
        </div>
      </div>

      {/* Adding space to ensure footer doesn't overlap */}
      <div className="mb-10"></div>
    </div>
  );
};

export default Restaurant;
