import React from "react";

const FormSection = ({
  formData,
  handleInputChange,
  handleEmailChange,
  formState,
  nameRef,
  emailRef,
  formRef, // Receiving formRef
}) => (
  <div
    ref={formRef} // Adding ref to this div to allow scrolling
    className="max-w-md mx-auto mt-4 bg-slate-600 backdrop-blur-lg rounded-lg shadow-lg border border-white/30 mb-4 relative z-10"
  >
    <div className="text-center mt-8">
      <h2 className="text-2xl font-semibold tracking-wider text-white font-oswald duration-500 ease-in-out transform hover:scale-105">
        Your Information
      </h2>
    </div>
    <form className="mt-6 p-6">
      {formState.formError && (
        <p className="text-red-500 text-lg font-bold mb-4 text-center">
          {formState.formError}
        </p>
      )}
      <div className="mb-4">
        <label className="block text-white font-semibold mb-2" htmlFor="name">
          Your Name <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="name"
          name="senderName"
          value={formData.senderName}
          onChange={handleInputChange}
          ref={nameRef} // Name input reference for focus
          className="w-full p-2 rounded-md text-gray-300 bg-black placeholder-gray-500 focus:outline-none"
          placeholder="Enter your name"
        />
      </div>
      <div className="mb-4">
        <label className="block text-white font-semibold mb-2" htmlFor="email">
          Your Email Address <span className="text-red-500">*</span>
        </label>
        <input
          type="email"
          id="email"
          name="senderEmail"
          value={formData.senderEmail}
          onChange={handleEmailChange}
          ref={emailRef} // Email input reference for focus
          className={`w-full p-2 rounded-md text-gray-300 bg-black placeholder-gray-500 focus:outline-none ${
            formState.emailError ? "border-2 border-red-500" : ""
          }`}
          placeholder="Enter your email address"
        />
        {formState.emailError && (
          <p className="text-red-500 text-sm mt-2">
            Please enter a valid email address.
          </p>
        )}
      </div>
    </form>
  </div>
);

export default FormSection;
