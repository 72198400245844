/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import productsData from "./productsData.js";

const Products = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibilityRatio, setVisibilityRatio] = useState(0); // To store visibility ratio
  const sectionRef = useRef(null); // Ref for the section
  const svgRef = useRef(null); // Ref for the SVG
  const intervalRef = useRef(); // To hold the interval reference

  // Function to go to the next product
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === productsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to go to the previous product
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? productsData.length - 1 : prevIndex - 1
    );
  };

  // Function to clear and restart the auto-slide interval
  const clearAndRestartInterval = () => {
    clearInterval(intervalRef.current); // Clear the existing interval
    startAutoSlide(); // Restart the interval
  };

  // Function to start the auto-slide interval
  const startAutoSlide = () => {
    if (visibilityRatio > 0) {
      intervalRef.current = setInterval(() => {
        handleNext();
      }, 5000); // 5 seconds interval
    }
  };

  // Start auto-slide when component mounts and when visibilityRatio changes
  useEffect(() => {
    if (visibilityRatio > 0) {
      startAutoSlide(); // Start the interval
    }

    return () => clearInterval(intervalRef.current); // Cleanup timer on unmount or visibility change
  }, [visibilityRatio]);

  // IntersectionObserver to detect if the section is in view and its visibility ratio
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setVisibilityRatio(entry.intersectionRatio); // Update visibility ratio
        });
      },
      { threshold: Array.from(Array(101).keys(), (v) => v / 100) } // Thresholds from 0 to 1 in increments of 0.01
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current); // Start observing the section
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current); // Stop observing when unmounted
      }
    };
  }, []);

  // Update the SVG width based on the visibility ratio
  useEffect(() => {
    if (svgRef.current) {
      const fullWidth = svgRef.current.parentElement.offsetWidth;
      const adjustedWidth = fullWidth * visibilityRatio;
      svgRef.current.style.width = `${adjustedWidth}px`;
    }
  }, [visibilityRatio]);

  // Function to handle dot click and show corresponding product
  const handleDotClick = (index) => {
    setCurrentIndex(index);
    clearAndRestartInterval(); // Reset the timer when a dot is clicked
  };

  return (
    <div
      ref={sectionRef}
      className="max-w-full mx-auto p-8 bg-white/10 rounded-lg relative"
    >
      {/* Heading with squiggly underline effect */}
      <h2 className="text-4xl font-dancing-script font-bold text-center mb-8 relative">
        Products
        {/* Squiggly line SVG */}
        <svg
          viewBox="0 0 100 20"
          preserveAspectRatio="none"
          height="20"
          ref={svgRef}
          className="mx-auto mt-1 transition-all duration-300 ease-in-out"
        >
          <path
            d="M0 10 Q 25 0, 50 10 T 100 10"
            stroke="#facc15"
            strokeWidth="3"
            fill="transparent"
          />
        </svg>
      </h2>

      <div className="relative w-full">
        {/* Only show the currently selected product */}
        <div className="flex justify-center">
          <div className="w-full max-w-sm p-6 bg-white/15 rounded-2xl text-center shadow-lg">
            <div className="relative">
              {/* Image with error handling */}
              <a
                href="https://www.ubereats.com/za/store/flavours-of-india-rsa/50yYiDBJQl2Z9wPDQEEhug"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={productsData[currentIndex].image}
                  alt={productsData[currentIndex].name}
                  onError={(e) =>
                    (e.target.src = "https://via.placeholder.com/150")
                  } // Placeholder image on error
                  className="w-44 h-44 border-4 border-yellow-500 object-cover mx-auto mb-5 rounded-full duration-500 ease-in-out transform hover:scale-105 mt-3 hover:border-white"
                />
              </a>
            </div>
            <h3 className="text-2xl font-bold">
              {productsData[currentIndex].name}
            </h3>
            <p className="text-lg text-gray-400">
              {productsData[currentIndex].menuSection}
            </p>
            <p className="text-lg text-gray-400">
              {productsData[currentIndex].section}
            </p>
            <p className="text-lg font-bold mt-2 text-yellow-500">
              R{productsData[currentIndex].price}
            </p>
          </div>
        </div>

        {/* Left Arrow Button */}
        <button
          className="absolute top-1/2 ml-2 left-0 transform -translate-y-1/2 text-white bg-yellow-500 hover:bg-white hover:text-yellow-500 p-2 rounded-full"
          onClick={() => {
            handlePrevious();
            clearAndRestartInterval();
          }}
        >
          &lt;
        </button>

        {/* Right Arrow Button */}
        <button
          className="absolute top-1/2 mr-2 right-0 transform -translate-y-1/2 text-white bg-yellow-500 hover:bg-white hover:text-yellow-500 p-2 rounded-full"
          onClick={() => {
            handleNext();
            clearAndRestartInterval();
          }}
        >
          &gt;
        </button>
      </div>

      {/* Slider Dots */}
      <div className="flex justify-center mt-4">
        {productsData.map((_, index) => (
          <span
            key={index}
            className={`h-3 w-3 mx-2 rounded-full cursor-pointer transition-all duration-500 ${
              index === currentIndex ? "bg-yellow-500" : "bg-gray-500"
            }`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>

      <div className="text-center mt-7">
        <a
          href="https://www.ubereats.com/za/store/flavours-of-india-rsa/50yYiDBJQl2Z9wPDQEEhug"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="w-full py-2 bg-green-600 text-white font-semibold rounded-md transition-all duration-500 ease-in-out transform hover:scale-105 hover:bg-white hover:text-green-500">
            Explore more...
          </button>
        </a>
      </div>
    </div>
  );
};

export default Products;
