/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback, useMemo } from "react";
import emailjs from "emailjs-com";
import debounce from "lodash.debounce";
import Modal from "./Modal";
import FormSection from "./FormSection";
import MessageSection from "./MessageSection";
// Import DOMPurify for sanitizing inputs
import DOMPurify from "dompurify";

const HowCanWeHelp = () => {
  const receiverEmail = "sales@v4uafrica.com";

  const [formData, setFormData] = useState({
    senderName: "",
    senderEmail: "",
    message: "",
  });

  const [formState, setFormState] = useState({
    isSubmitted: false,
    isSubmitting: false,
    formError: "",
    emailError: false,
    messageError: false,
  });

  const formRef = useRef(null);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const textareaRef = useRef(null);

  // Email validation function (used for both immediate and final validation)
  const validateEmailFormat = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  // Function to check if the input contains a URL
  const containsLink = (text) => {
    const urlPattern = /https?:\/\/|www\./; // Check if text contains "http", "https", or "www."
    const domainPattern = /\.(com|co|in|net|org|info|biz|gov|edu)$/; // Check for common domain suffixes
    return urlPattern.test(text) || domainPattern.test(text);
  };

  // Debounced email validation function
  const debouncedValidateEmail = useMemo(
    () =>
      debounce((email) => {
        const isValid = validateEmailFormat(email);
        setFormState((prev) => ({
          ...prev,
          emailError: !isValid,
        }));
      }, 300),
    []
  );

  const sanitizeInput = (value) => {
    return DOMPurify.sanitize(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = sanitizeInput(value); // Sanitize the input
    setFormData((prev) => ({ ...prev, [name]: sanitizedValue }));

    setFormState((prev) => ({
      ...prev,
      formError: "",
      messageError: false,
      emailError: false, // Reset errors on input change
    }));
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    handleInputChange(e);

    // Immediate validation to provide instant feedback
    const isValid = validateEmailFormat(value);
    setFormState((prev) => ({
      ...prev,
      emailError: !isValid, // Show error if not valid immediately
    }));

    // Debounced final validation to prevent too frequent updates
    debouncedValidateEmail(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { senderName, senderEmail, message } = formData;

    // Check if the name field is empty
    if (!senderName.trim()) {
      setFormState((prev) => ({
        ...prev,
        formError: "Please fill these details to send your message.",
      }));
      formRef.current?.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to "Your Information"
      nameRef.current?.focus(); // Focus on name input
      return;
    }

    // Check if the name field contains a link
    if (containsLink(senderName)) {
      setFormState((prev) => ({
        ...prev,
        formError: "Name cannot contain a link.",
      }));
      nameRef.current?.focus(); // Focus on name input
      return;
    }

    // Validate email
    if (!senderEmail.trim()) {
      setFormState((prev) => ({
        ...prev,
        formError: "Please fill these details to send your message.",
        emailError: true,
      }));
      formRef.current?.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to "Your Information"
      emailRef.current?.focus(); // Focus on email input
      return;
    }

    if (!validateEmailFormat(senderEmail)) {
      setFormState((prev) => ({
        ...prev,
        formError: "Please enter a valid email address.",
        emailError: true,
      }));
      emailRef.current?.focus(); // Focus on email input
      return;
    }

    // Validate message
    if (!message.trim()) {
      setFormState((prev) => ({ ...prev, messageError: true }));
      textareaRef.current?.focus(); // Focus on message textarea
      return;
    }

    setFormState((prev) => ({ ...prev, isSubmitting: true }));

    const emailParams = {
      from_name: senderName,
      from_email: senderEmail,
      to_email: receiverEmail,
      reply_to: senderEmail,
      message,
    };

    emailjs
      .send(
        "service_1c5wm45",
        "template_klk74s1",
        emailParams,
        "PsBOworuyrcpefWKA"
      )
      .then(() => {
        setFormState({
          isSubmitted: true,
          isSubmitting: false,
          formError: "",
          emailError: false,
          messageError: false,
        });
        setFormData({ senderName: "", senderEmail: "", message: "" });
      })
      .catch((error) => {
        console.error("EmailJS error: ", error);
        setFormState((prev) => ({
          ...prev,
          formError: "Failed to send email. Please try again later.",
          isSubmitting: false,
        }));
      });
  };

  const handleClear = useCallback(() => {
    setFormData({ senderName: "", senderEmail: "", message: "" });
    setFormState({
      isSubmitted: false,
      isSubmitting: false,
      formError: "",
      emailError: false,
      messageError: false,
    });
  }, []);

  const closeModal = useCallback(() => {
    setFormState((prev) => ({ ...prev, isSubmitted: false }));
  }, []);

  return (
    <>
      {formState.isSubmitted && <Modal closeModal={closeModal} />}
      <FormSection
        formData={formData}
        handleInputChange={handleInputChange}
        handleEmailChange={handleEmailChange}
        formState={formState}
        nameRef={nameRef}
        emailRef={emailRef}
        formRef={formRef} // Passing the form reference to scroll
      />
      <MessageSection
        handleSubmit={handleSubmit}
        handleClear={handleClear}
        formData={formData}
        handleInputChange={handleInputChange}
        formState={formState}
        textareaRef={textareaRef}
      />
    </>
  );
};

export default HowCanWeHelp;
