/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import galleryImages from "./galleryImages"; // Import the image data

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const V4Ugallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [resetTimer, setResetTimer] = useState(false); // State to track timer reset

  // Auto-slide the images every 7 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 7000); // Change image every 7 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [resetTimer, galleryImages.length]); // Depend on `resetTimer` to reset the timer

  // Function to navigate to specific image when a dot is clicked
  const handleDotClick = (index) => {
    setCurrentIndex(index); // Update the current index
    setResetTimer((prev) => !prev); // Toggle resetTimer to restart the interval
  };

  // Function to go to the next image
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
    );
    setResetTimer((prev) => !prev); // Reset the timer when the button is clicked
  };

  // Function to go to the previous image
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
    );
    setResetTimer((prev) => !prev); // Reset the timer when the button is clicked
  };

  return (
    <div className="relative w-full max-w-2xl mx-auto overflow-hidden">
      {/* Carousel images */}
      <div
        className="flex transition-transform duration-700 ease-in-out"
        style={{
          width: `${galleryImages.length * 100}%`, // Set container width based on number of images
          transform: `translateX(-${
            currentIndex * (100 / galleryImages.length)
          }%)`, // Properly translate based on current index
        }}
      >
        {galleryImages.map((image) => (
          <div
            key={image.id}
            style={{
              width: "100%", // Each image takes 100% of the viewport width
            }}
          >
            <img
              src={image.imageUrl}
              alt={image.name}
              className="w-full h-80 object-cover"
              style={{
                height: "400px",
                borderRadius: "1rem",
                overflow: "hidden",
              }}
            />
          </div>
        ))}
      </div>

      {/* Previous and Next buttons */}
      <button
        className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full hover:bg-white hover:text-gray-800"
        onClick={handlePrev}
      >
        <FaArrowLeft size={20} />
      </button>
      <button
        className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full hover:bg-white hover:text-gray-800"
        onClick={handleNext}
      >
        <FaArrowRight size={20} /> {/* Right arrow icon */}
      </button>

      {/* Functional Slider dots */}
      <div className="flex justify-center mt-4 space-x-3">
        {galleryImages.map((_, index) => (
          <div
            key={index}
            onClick={() => handleDotClick(index)} // Handle dot click to change image
            className={`cursor-pointer w-3 h-3 rounded-full hover:rounded-full transition-all duration-500 ${
              currentIndex === index ? "bg-yellow-500" : "bg-gray-400"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default V4Ugallery;
