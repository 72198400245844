import React from "react";

const Modal = ({ closeModal }) => (
  <div
    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 modal-backdrop"
    onClick={closeModal} // This will close the modal when clicking on the backdrop
  >
    <div
      className="bg-white rounded-lg p-8 text-center shadow-lg"
      onClick={(e) => e.stopPropagation()} // Prevent the closeModal from being triggered when clicking inside the modal content
    >
      <h2 className="text-2xl font-semibold mb-4 text-gray-900">
        Thanks for contacting us!
      </h2>
      <p className="text-gray-600">We will be in touch with you shortly.</p>
      <button
        onClick={closeModal} // Close the modal when clicking this button
        className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
      >
        Close
      </button>
    </div>
  </div>
);

export default Modal;
