import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Card component
export const Card = ({ className, children, ...props }) => {
  return (
    <div
      className={clsx(
        "bg-white shadow-md rounded-lg overflow-hidden",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

// Card Header component
export const CardHeader = ({ className, children, ...props }) => {
  return (
    <div className={clsx("p-4 border-b", className)} {...props}>
      {children}
    </div>
  );
};

// Card Title component
export const CardTitle = ({ className, children, ...props }) => {
  return (
    <h2 className={clsx("text-5xl font-bold", className)} {...props}>
      {children}
    </h2>
  );
};

// Card Description component
export const CardDescription = ({ className, children, ...props }) => {
  return (
    <p
      className={clsx("text-md text-white mt-3 text-center", className)}
      {...props}
    >
      {children}
    </p>
  );
};

// Card Content component
export const CardContent = ({ className, children, ...props }) => {
  return (
    <div className={clsx("p-4 pb-0", className)} {...props}>
      {children}
    </div>
  );
};

// Prop types for each component
Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CardHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CardTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CardDescription.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CardContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
