import React from "react";

const MessageSection = ({
  handleSubmit,
  handleClear,
  formData,
  handleInputChange,
  formState,
  textareaRef,
}) => (
  <div className="max-w-md mx-auto mt-4 bg-slate-600 backdrop-blur-lg rounded-lg shadow-lg border border-white/30 mb-16 relative z-10">
    <div className="text-center mt-8">
      <h2 className="text-2xl font-semibold tracking-wider text-white font-oswald duration-500 ease-in-out transform hover:scale-105">
        How Can we Help You?
      </h2>
    </div>
    <form onSubmit={handleSubmit} className="mt-6 p-6">
      <p className="text-white font-acme mb-2 text-2xl tracking-wider">
        Your message will be sent to our email!
      </p>
      <textarea
        name="message"
        placeholder="Your message..."
        value={formData.message}
        onChange={handleInputChange}
        ref={textareaRef}
        className={`w-full p-2 rounded-md text-gray-300 bg-black placeholder-gray-500 focus:outline-none ${
          formState.messageError ? "border-2 border-red-500" : ""
        }`}
        rows="4"
      ></textarea>
      {/* Display error message if messageError is true */}
      {formState.messageError && (
        <p className="text-red-500 mt-2 text-center text-lg">
          Message cannot be empty.
        </p>
      )}
      <div className="mt-4 flex justify-between space-x-4">
        <button
          type="submit"
          className="w-full px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-white hover:text-blue-500 focus:outline-none focus:ring-4 focus:ring-blue-500 transition-all duration-300 ease-in-out"
        >
          {formState.isSubmitting ? "Submitting..." : "SUBMIT"}
        </button>
        <button
          type="button"
          onClick={handleClear}
          className="w-full px-4 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-white hover:text-red-500 focus:outline-none focus:ring-4 focus:ring-red-500 transition-all duration-300 ease-in-out"
        >
          CLEAR
        </button>
      </div>
    </form>
  </div>
);

export default MessageSection;
