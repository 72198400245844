// Section constants to reduce repetition
const SECTIONS = {
  NON_VEGETARIAN_STARTERS: "Non Vegetarian Starters",
  VEGETARIAN_CURRIES: "Vegetarian Curries",
  NON_VEGETARIAN_CURRIES: "Non Vegetarian Curries",
  BREADS: "Breads",
};

const productsData = [
  {
    id: 1,
    name: "Lamb Chilly",
    price: 164.99, // Changed to a number for easier calculations
    section: SECTIONS.NON_VEGETARIAN_STARTERS,
    image:
      "https://www.santamariaworld.com/optimized/maximum/globalassets/uk/recipes/lamb_chili.jpg",
  },
  {
    id: 2,
    name: "Lamb Pepper Dry Fry",
    price: 164.99, // Consistent price formatting
    section: SECTIONS.NON_VEGETARIAN_STARTERS,
    image:
      "https://th.bing.com/th/id/OIP.2_ickXKAumxL3jHB8MsPMwAAAA?rs=1&pid=ImgDetMain",
  },
  {
    id: 3,
    name: "Aloo Jeera",
    price: 95.0,
    section: SECTIONS.VEGETARIAN_CURRIES,
    image:
      "https://d1ralsognjng37.cloudfront.net/c59dea52-03c4-4362-ba07-54eac4a986bc.jpeg",
  },
  {
    id: 4,
    name: "Aloo Palak Curry",
    price: 105.0,
    section: SECTIONS.VEGETARIAN_CURRIES,
    image:
      "https://d1ralsognjng37.cloudfront.net/c09c9620-4f1c-4dfd-afb4-0a65fc5f7e9f.jpeg",
  },
  {
    id: 5,
    name: "Aloo Paratha",
    price: 40.0,
    section: SECTIONS.BREADS,
    image:
      "https://tb-static.uber.com/prod/image-proc/processed_images/7594b8777a60d0eb34b370fa56c3bd86/58f691da9eaef86b0b51f9b2c483fe63.jpeg",
  },
  {
    id: 6,
    name: "Butter Chicken",
    price: 139.99,
    section: SECTIONS.NON_VEGETARIAN_CURRIES,
    image:
      "https://tb-static.uber.com/prod/image-proc/processed_images/425caa1286433e9c65888ef951aa02fb/58f691da9eaef86b0b51f9b2c483fe63.jpeg",
  },
  // Add more products...
];

export default productsData;
