import React from "react";
import businessHoursData from "./businessHoursData"; // Adjust the path if necessary

const BusinessHours = () => {
  return (
    <div className="text-center mt-7 bg-gray-900 text-white p-5 rounded-lg">
      <h2 className="text-4xl font-dancing-script font-semibold mb-10 relative inline-block">
        Business hours
        <span className="absolute left-0 right-0 bottom-[-10px] w-full mx-auto h-1 bg-yellow-500 "></span>
      </h2>
      <div className="flex flex-col space-y-2 text-lg">
        {businessHoursData.map((item, index) => (
          <div key={item.day}>
            <div className="flex justify-between">
              <span className="text-yellow-500 mb-1 text-left">
                {item.day}:
              </span>
              <span className="text-right">{item.hours}</span>
            </div>
            {index < businessHoursData.length - 1 && (
              <hr className="border-t-2 border-gray-600 rounded-full m-2" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessHours;
