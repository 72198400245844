import React, { useContext } from "react";
import {
  FaAddressCard,
  FaWhatsapp,
  FaHome,
  FaQrcode,
  FaShareAlt,
  FaChartBar, // Import the dashboard icon
} from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import PhoneNumberContext from "../../context/PhoneNumberContext"; // Import the context
import UserContext from "../../context/UserContext";

const FooterNav = () => {
  const { phoneNumber } = useContext(PhoneNumberContext);
  const { user } = useContext(UserContext); // Get user data from context

  const navigate = useNavigate(); // Use useNavigate hook for navigation

  const url = "https://manimozhi.vcard-aam.com/";

  // Helper function to check if a cookie exists
  const checkCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  // Function to trigger download of vCard and update the add-to-contact count
  const handleAddToContacts = async () => {
    const userId = user.userId; // Assuming userId is available here

    const vCardData = `BEGIN:VCARD
  VERSION:3.0
  FN:Mani Shiva Shankar
  TEL;TYPE=CELL:${phoneNumber}
  END:VCARD`;

    const blob = new Blob([vCardData], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "contact.vcf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    // Only update the count once per session
    const existingCookie = checkCookie(`add_to_contact_${userId}`);

    // Log cookie status for debugging
    // console.log(`Existing cookie check: ${existingCookie}`);

    if (!existingCookie) {
      // After the download, we update the add-to-contact count only if not already done
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}add-to-contact`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId }), // Passing userId here
          }
        );

        if (response.ok) {
          // Set a cookie to prevent multiple add-to-contact requests in the same session
          document.cookie = `add_to_contact_${userId}=true; max-age=604800; path=/`; // Cookie expires in 7 days
          console.log("Contact added successfully");
        } else {
          const errorData = await response.json();
          console.error("Failed to update add-to-contact count:", errorData);
        }
      } catch (error) {
        console.error("Error updating add-to-contact count:", error);
      }
    }
    //  else {
    //   console.log("Add to contact already recorded for this session.");
    // }
  };

  // Function to handle sharing the vCard using the Web Share API and updating the vCard share count
  const handleShare = async () => {
    const shareData = {
      title: "VCard - Manimozhi",
      text: `Hey! You can check out my VCard using the link below. Feel free to save or share it with others: ${url}`,
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);

        // Attempt to record the share on the server
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}vcard-share`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId: 1 }), // Assuming userId is 1
          }
        );

        const data = await response.json();
        if (response.ok) {
          // console.log("vCard share recorded successfully:", data.message);
        } else {
          console.log("vCard share error:", data.message);
        }
      } else {
        alert("Your browser does not support the Web Share API.");
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  // Function to navigate to the dashboard
  const navigateToDashboard = () => {
    navigate("/dashboard"); // Navigate to the dashboard page
  };

  return (
    <footer className="fixed bottom-0 left-0 right-0 mx-auto max-w-md w-full bg-yellow-400 rounded-t-lg shadow-lg overflow-hidden">
      <div className="flex justify-around py-2 bg-yellow-400">
        <button
          onClick={handleAddToContacts}
          className="flex-1 text-center py-2"
        >
          <FaAddressCard className="text-gray-700 font-bold text-2xl mx-auto" />
        </button>

        <a
          href={`https://wa.me/${phoneNumber}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex-1 text-center py-2"
        >
          <FaWhatsapp className="text-gray-700 text-2xl mx-auto" />
        </a>

        <a href="/" className="flex-1 text-center py-2">
          <FaHome className="text-gray-700 text-2xl mx-auto" />
        </a>

        <a href="/qr-code" className="flex-1 text-center py-2">
          <FaQrcode className="text-gray-700 text-2xl mx-auto" />
        </a>

        <button onClick={handleShare} className="flex-1 text-center py-2">
          <FaShareAlt className="text-gray-700 text-2xl mx-auto" />
        </button>

        {/* New Dashboard Button */}
        <button
          onClick={navigateToDashboard}
          className="flex-1 text-center py-2"
        >
          <FaChartBar className="text-gray-700 text-2xl mx-auto" />
        </button>
      </div>
    </footer>
  );
};

export default FooterNav;
