/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils, faConciergeBell } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash.debounce";

const servicesData = [
  {
    id: 1,
    title: "Cooking",
    description:
      "Delight your taste buds with our authentic dishes, crafted with fresh ingredients and traditional flavors to bring the taste of home to your plate.",
    icon: faUtensils,
  },
  {
    id: 2,
    title: "Catering",
    description:
      "Our professional catering services bring gourmet food and a seamless dining experience to your events, making every occasion memorable.",
    icon: faConciergeBell,
  },
];

const V4Uservices = () => {
  const spanRef = useRef(null);
  const sectionRef = useRef(null);
  const [visibilityRatio, setVisibilityRatio] = useState(0);

  // Debounced function to handle visibility ratio change
  const handleVisibilityChange = useCallback(
    debounce((ratio) => {
      setVisibilityRatio(ratio);
    }, 100),
    []
  );

  // Intersection Observer to detect if the section is in view and its visibility ratio
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) =>
          handleVisibilityChange(entry.intersectionRatio)
        );
      },
      { threshold: Array.from(Array(101).keys(), (v) => v / 100) }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [handleVisibilityChange]);

  // Adjust the underline width based on the visibility ratio
  useEffect(() => {
    if (spanRef.current) {
      const fullWidth = spanRef.current.parentElement.offsetWidth;
      const adjustedWidth = fullWidth * visibilityRatio;
      spanRef.current.style.width = `${adjustedWidth}px`;
    }
  }, [visibilityRatio]);

  return (
    <div
      ref={sectionRef}
      className="services-section max-w-md mx-auto mt-7 text-center text-white py-10 bg-gray-700 rounded-lg shadow-lg p-6"
    >
      {/* Heading */}
      <h1 className="text-3xl font-bold text-center mb-6 text-white font-dancing-script">
        Our Services
        <span
          ref={spanRef}
          className="block mx-auto h-1 bg-yellow-500 mb-4 transition-all duration-700 ease-in-out"
        ></span>
      </h1>

      {/* Services Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {servicesData.map((service) => (
          <div
            key={service.id}
            className="service-card bg-white/20 hover:bg-white/10 duration-500 ease-in-out transform hover:scale-105 p-6 rounded-2xl text-center shadow-lg group"
          >
            <div className="mb-4">
              <FontAwesomeIcon
                icon={service.icon}
                className="w-16 h-16 transition-transform duration-300 ease-in-out text-white group-hover:text-yellow-500 group-hover:scale-125"
              />
            </div>
            <h3 className="text-xl font-bold">{service.title}</h3>
            <p className="text-sm mt-2">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default V4Uservices;
