import React from "react";
import {
  FaEnvelope,
  FaPhoneAlt,
  FaBirthdayCake,
  FaMapMarkerAlt,
  FaGlobe,
  FaUtensils,
} from "react-icons/fa";

const icons = {
  email: FaEnvelope,
  phone: FaPhoneAlt,
  birthday: FaBirthdayCake,
  location: FaMapMarkerAlt,
  website: FaGlobe,
  restaurant: FaUtensils,
};

const ContactInfo = ({
  type,
  label,
  value,
  href,
  target,
  showFullEmail,
  toggleEmail,
}) => {
  const Icon = icons[type];

  // Dynamically set href based on type
  const contactHref = type === "email" ? `mailto:${value}` : href;

  return (
    <div className="text-left">
      <div className="inline-block p-2 bg-gray-700 transition-colors duration-500 hover:bg-gray-900 rounded-lg">
        <Icon className="text-white duration-500 ease-in-out transform hover:scale-150 text-2xl" />
      </div>
      <h3 className="text-gray-400 font-new-amsterdam tracking-wider text-xl mt-2">
        {label}
      </h3>

      {type === "email" ? (
        <a
          href={contactHref}
          className={`block mx-auto font-acme text-lg text-yellow-600 border-transparent transition-all duration-500 ease-in-out transform hover:scale-110 cursor-pointer max-w-[180px] ${
            showFullEmail ? "" : "truncate"
          }`}
          onClick={toggleEmail}
        >
          {value}
        </a>
      ) : (
        <a
          href={contactHref}
          target={target}
          rel={target === "_blank" ? "noopener noreferrer" : undefined}
          className="block mx-auto font-acme text-lg text-yellow-600 border-transparent transition-all duration-500 ease-in-out transform hover:scale-110"
        >
          {value}
        </a>
      )}
    </div>
  );
};

export default ContactInfo;
