/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback } from "react";
import axios from "axios";
import ProfileHeader from "../common/ProfileHeader";
import { debounce } from "lodash";

const SearchCompany = () => {
  const [companyName, setCompanyName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const resultsRef = useRef(null); // Create a reference for the results section

  // Debounced search function to avoid multiple requests on Enter key hold
  const handleSearch = useCallback(
    debounce(async () => {
      if (!companyName.trim()) {
        setErrorMessage("Please fill in this field.");
        setSearchResults([]); // Clear previous search results
        return;
      }
      setErrorMessage(""); // Clear previous errors

      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_BASE_URL
          }search-company?name=${encodeURIComponent(companyName)}`
        );

        if (response.data.statusCode === 200 && response.data.data.companies) {
          setSearchResults(response.data.data.companies);

          // Scroll to the results section
          setTimeout(() => {
            if (resultsRef.current) {
              resultsRef.current.scrollIntoView({ behavior: "smooth" });
            }
          }, 100); // Slight delay to ensure DOM update
        } else if (response.data.statusCode === 404) {
          setSearchResults([]); // Clear previous search results if no data found
          setErrorMessage("No results found.");
        } else {
          setSearchResults([]);
          setErrorMessage("An unexpected error occurred. Please try again.");
        }
      } catch (error) {
        if (error.response) {
          const { status, data } = error.response;

          if (status === 400) {
            // Handle input validation or length restriction errors
            setErrorMessage(
              data.message || "Invalid request. Please check your input."
            );
          } else if (status === 429) {
            // Handle rate limiting errors
            setErrorMessage("Too many requests. Please try again later.");
          } else if (status === 404) {
            setErrorMessage("No results found.");
          } else {
            setErrorMessage("An unexpected error occurred. Please try again.");
          }
        } else {
          setErrorMessage(
            "Failed to fetch results. Please check your network connection."
          );
        }

        setSearchResults([]); // Clear previous search results on error
      }
    }, 300), // Debounce the function, setting a delay of 300ms
    [companyName]
  );

  const handleCancel = () => {
    setCompanyName(""); // Clear the input field
    setSearchResults([]); // Clear the search results
    setErrorMessage(""); // Clear any error messages
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <ProfileHeader />
      <div className="max-w-md mx-auto mt-5 mb-16 bg-gray-800 backdrop-blur-lg rounded-lg shadow-lg p-6 border border-white/10">
        <div className="bg-white/20 bg-opacity-50 mt-1 tracking-wider text-xl text-white font-bold py-2 px-4 rounded-md backdrop-blur-lg shadow-lg border border-white/10">
          <h3 className="text-2xl ml-1 font-bold text-white font-asap-condensed tracking-wider pt-2">
            Search a Company
          </h3>

          <div>
            <input
              type="text"
              placeholder="Enter a name ..."
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              onKeyDown={handleKeyDown} // Trigger search on Enter key, with debounce
              className="w-full p-2 mt-4 rounded-md border-2 border-gray-700 hover:border-gray-300 text-gray-800"
              spellCheck="false"
            />
          </div>

          <div className="mt-4 flex flex-col sm:flex-row justify-between space-y-3 sm:space-y-0">
            <button
              onClick={handleCancel}
              className="font-asap-condensed px-4 py-2 bg-red-500 font-bold text-white rounded-md duration-500 ease-in-out transform hover:scale-110 hover:bg-gray-100 hover:text-red-600"
            >
              CLEAR
            </button>
            <button
              onClick={handleSearch}
              className="font-asap-condensed bg-black tracking-wider text-lg text-white duration-500 ease-in-out transform hover:scale-110 font-bold py-2 px-4 rounded-md hover:bg-white/60 backdrop-blur-lg shadow-lg border border-white/10 hover:text-gray-700"
            >
              SEARCH
            </button>
          </div>
          {errorMessage && (
            <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
          )}
        </div>

        {/* Display search results */}
        {searchResults.length > 0 && (
          <div
            ref={resultsRef} // Set the ref to the results section
            className="bg-white/20 bg-opacity-50 mt-6 tracking-wider text-xl text-white font-bold py-2 px-4 rounded-md backdrop-blur-lg shadow-lg border border-white/10"
          >
            {searchResults.map((company, index) => (
              <div
                key={index}
                className="flex flex-col sm:flex-row items-center justify-between mt-5 mb-5 bg-white/20 p-3 rounded-xl duration-500 ease-in-out transform hover:scale-105"
              >
                {/* Image on the left */}
                <div className="flex-shrink-0 w-full sm:w-auto flex justify-center sm:justify-start">
                  <a
                    href={company.vcard_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={company.profile_pic}
                      alt={company.company_name}
                      className="w-24 h-24 sm:w-36 sm:h-36 rounded-full duration-500 ease-in-out transform hover:scale-110 border-4 border-white-800 hover:border-yellow-500"
                    />
                  </a>
                </div>

                {/* Details on the right */}
                <div className="ml-0 sm:ml-6 mt-2 sm:mt-0 text-center sm:text-left">
                  <div className="py-2">
                    <h3 className="text-xl sm:text-2xl font-bebas-neue tracking-wider font-semibold text-white duration-500 ease-in-out transform hover:scale-110 mt-4">
                      {`${company.company_name} ${
                        company.company_alias_name || ""
                      }`}
                    </h3>
                    <p className="text-yellow-400 text-lg font-bold font-asap-condensed tracking-widest">
                      {company.company_location}
                    </p>
                  </div>

                  <div className="text-center sm:text-left mt-2">
                    <button
                      onClick={() => window.open(company.vcard_link, "_blank")}
                      className="bg-green-600 font-asap-condensed tracking-widest text-gray-100 py-2 px-3 rounded-md text-center duration-500 ease-in-out transform hover:scale-110 hover:bg-white hover:text-green-600"
                    >
                      VIEW VCARD
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchCompany;
