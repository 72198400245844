import React, { createContext, useState, useEffect } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Use the dynamic API URL from environment variables
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        // console.log("API Base URL:", baseURL);
        const response = await fetch(`${baseURL}user-profile?userId=1`); // Ensure the userId is dynamically set based on the logged-in session
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setUser({ userId: data.id, name: data.name });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  if (!user) {
    return <div>Loading...</div>; // Loading indicator while user data is being fetched
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
