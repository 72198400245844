import React from "react";

const SearchResults = ({ searchResults }) => {
  return (
    <div className="mt-6 overflow-x-auto rounded-md">
      {searchResults.length > 0 && (
        <table className="min-w-full bg-white/20 bg-opacity-50 mt-6 tracking-wider text-sm text-white font-bold py-2 px-4 rounded-md backdrop-blur-lg shadow-lg border border-white/10">
          <thead>
            <tr className="bg-gray-400 font-lilita text-lg tracking-wider">
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-left">FBOID</th>
              <th className="px-4 py-2 text-left">Title</th>
              <th className="px-4 py-2 text-left">Country</th>
              <th className="px-4 py-2 text-left">Email</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((result, index) => (
              <tr key={index} className="border-t">
                <td className="px-4 py-2">{result.name}</td>
                <td className="px-4 py-2">{result.fboid}</td>
                <td className="px-4 py-2">{result.title}</td>
                <td className="px-4 py-2">{result.country}</td>
                <td className="px-4 py-2">{result.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SearchResults;
